<template>
  <div class="new_info_container">
    <div class="content">
      <div class="title">{{info.title}}</div>
      <div class="intro">
        <div class="time">
          发布时间：{{dateFormat(info.issue_time, "y-m-d")}}
        </div>
        <div class="look-num">
          浏览量：{{info.look_num}}
        </div>
      </div>

      <div class="editor" v-html="info.content">
      </div>

      <div class="action">
        <div class="buttons">
          <div :class="{pre:true, not:!info.pre}" @click="reload(info.pre)">上一篇</div>
          <div :class="{next:true, not:!info.next}" @click="reload(info.next)">下一篇</div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      info: {},
    };
  },
  created() {
    this.getInfo(this.$route.query.id);
  },

  methods: {
    getInfo(id) {
      this.$api("/official/page/news_info", {
        id: id,
      }).then(({ data }) => {
        this.info = data;

        window.scrollTo(0, 0);
      });
    },
    reload(id) {
      if (!id) {
        return;
      }
      this.getInfo(id);
    },
  },
};
</script>

<style lang="scss" scoped>
.new_info_container {
  padding-top: 110px;
  background: #f5f5f5;
  display: flex;
  justify-content: center;
  padding-bottom: 80px;

  .content {
    width: 1180px;
    background-color: #fff;

    .title {
      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      text-align: center;
      padding-top: 80px;
    }

    .intro {
      padding-top: 10px;
      font-size: 14px;
      color: #999999;
      width: 388px;
      line-height: 22px;

      display: flex;
      justify-content: space-between;

      margin: 0 auto;
    }

    .editor {
      padding-top: 45px;
      padding-bottom: 73px;
      margin-left: 198px;
      margin-right: 197px;
    }

    ::v-deep {
      img {
        max-width: 100%;
      }
    }

    .action {
      padding-bottom: 75px;
      display: flex;
      justify-content: center;

      .buttons {
        display: flex;

        .pre,
        .next {
          user-select: none;
          margin: 0 20px;
          width: 155px;
          height: 32px;
          background: linear-gradient(180deg, #ffa83a 0%, #ff6f1b 100%);
          border-radius: 16px;
          border: 1px solid #fff;
          cursor: pointer;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;

          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 22px;

          transition: 0.5s;

          &.not {
            cursor: default;
            opacity: 0.5;
          }

          &:hover:not(.not) {
            color: #ff6f1b;
            background: #fff;
            border: 1px solid #ff6f1b;
          }
        }
      }
    }
  }
}
</style>